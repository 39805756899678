import React, {useEffect, useState} from "react"
import {graphql} from "gatsby"
import ArticuloPage from "../../components/articulos/articulo";
import ArticleDetail from "../../components/balanz-university/ArticleDetail";

const UniversityDynamicRoute = ({data}) => {
    const art = data.universityArticles;
    const artId = art.artid;
    const artSlug = art.slug;
    const artTitle = art.title;
    const artMetaTitle = art.metatitle;
    const artDesc = art.description;
    const artImgUrl = art.imgUrl;
    const htmlContent = art.htmlcontent;
    const timestamp = art.timestamp;
    const artDate = art.date;

  const [showDynamicContent, setShowDynamicContent] = useState(false);

  useEffect(() => {
    setShowDynamicContent(true);
  }, []);

    return (
        showDynamicContent
            ? <ArticleDetail slug={artSlug}/>
            : <ArticuloPage
            artid={artId}
            slug={artSlug}
            title={artTitle}
            metatitle={artMetaTitle}
            description={artDesc}
            imgurl={artImgUrl}
            htmlcontent={htmlContent}
            timestamp={timestamp}
            artDate={artDate}
        />
      )
}

export const query = graphql`
  query ($id: String) {
      universityArticles(id: { eq: $id}) {
        artid
        slug
        title
        metatitle
        description
        htmlcontent
        section
        imgUrl
        date
        timestamp
      }
    }
`

export default UniversityDynamicRoute
